import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { LazyLoadedListStore } from '../../filteredListStore';
import { ProductListItemComponent } from '../product-list-item/product-list-item.component';
import { ButtonPrimaryComponent } from '../button-primary/button-primary.component';
import { Schemas } from '../../../api-types/storeApiTypes';
import { ListState } from '../../listStore';
import { SpinnerComponent } from '../spinner/spinner.component';
import { StaticAssetComponent } from '../static-asset/static-asset.component';
import { AnalyticsService } from '../../analytics.service';

@Component({
  selector: 'app-product-list',
  imports: [
    ProductListItemComponent,
    ButtonPrimaryComponent,
    SpinnerComponent,
    StaticAssetComponent,
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent {
  store = input.required<
    ListState<Schemas['Product']> | LazyLoadedListStore<Schemas['Product']>
  >();
  analytics = inject(AnalyticsService);
  canReset = input.required<boolean>();
  resetted = output<void>();
  selected = output<void>();
  backlink = input<string | string[]>();

  constructor() {
    effect(() => {
      const state = this.store();
      if (state.elements.length > 0) {
        this.analytics.event('view_item_list', {
          item_list_id: 'products',
          item_list_name: 'Products',
          items: state.elements.map((e, i) => ({
            item_id: e.id,
            item_name: e.name,
            index: i,
            price: e.calculatedPrice.totalPrice,
            quantity: 1,
          })),
        });
      }
    });
  }

  loadMore = async () => {
    const store = this.store();
    if ('loadMore' in store) {
      return store.loadMore();
    }
  };

  doReset = () => {
    this.resetted.emit();
  };

  emitSelected(product: Schemas['Product'], i: number) {
    this.selected.emit();
    this.analytics.event('select_item', {
      item_list_id: 'products',
      item_list_name: 'Products',
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          index: i,
          price: product.calculatedPrice.totalPrice,
          quantity: 1,
        },
      ],
    });
  }
}
